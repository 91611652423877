<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout justify-center class="mt-5">
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <!-- <v-img
                :src="require('../../assets/logo.svg')"
                contain
                height="150"
              /> -->
              <v-card-text>
                <v-form>
                  <v-text-field
                    prepend-icon="mdi-api"
                    solo
                    dense
                    v-model="item.urlAPI"
                    name="urlAPI"
                    label="Ex. https://api.accd.app/api"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-account-tie"
                    solo
                    dense
                    v-model="item.tenanty"
                    name="tenanty"
                    label="Tenanty"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-account"
                    solo
                    dense
                    v-model="item.email"
                    name="email"
                    label="Email"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    solo
                    dense
                    prepend-icon="mdi-lock"
                    v-model="item.password"
                    name="password"
                    label="Senha"
                    type="password"
                    @keydown.enter.prevent="signIn"
                  ></v-text-field>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark @click="signIn">Acessar</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import notifyConfigMixin from '../../mixins/notifyConfigMixin';
export default {
  mixins: [notifyConfigMixin],
  name: 'Login',
  data() {
    return {
      item: { urlAPI: 'https://api.accd.app/api' },
    };
  },
  methods: {
    async signIn() {
      let res = await this.$auth.signIn(this.item);
      if (res.data.success) {
        this.notifySuccess('Usuário Logado');
        return this.$router.push({ name: 'Schemas' });
      } else if (!res.data.success) {
        this.notifyError(res);
      }
    },
  },
};
</script>

<style></style>
